<template>
  <div class="hosts">
    <div class="bread">
      <i class="el-icon-arrow-left" @click="goback()"></i>
      讲者主持
    </div>
    <div class="content">
      <div class="box" v-for="item in hostsList" :key="item.id">
        <div class="pic" @click="todialog(item)">
          <img v-if="item.pic1" :src="item.pic1" alt="" />
        </div>
        <div class="text">
          <h3>{{ item.name }}</h3>
          <p>{{ item.post }}</p>
        </div>
        <div class="btn" @click="todialog(item)">简介</div>
      </div>
    </div>
    <div class="bg3"></div>
    <el-dialog
      class="mydialog"
      :visible.sync="dialogVisible"
      width="80%"
      top="10vh"
      :show-close="false"
    >
      <img :src="dialogData.pic2" alt="" />
      <div class="title">
        <h3>{{ dialogData.name }}</h3>
        <h3>{{ dialogData.post }}</h3>
      </div>
      <p v-html="dialogData.text"></p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="mybtn" @click="dialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      dialogData: {},
      hostsList: [
        {
          id: 6,
          name: "王晨",
          post: "主任医师，教授，博士生导师",
          pic1: require("@/assets/image2/host/王晨A.png"),
          pic2: require("@/assets/image2/host/王晨B.png"),
          text: "主任医师，教授，博士生导师北京市社区卫生协会会长，北京医学会副会长，医学伦理分会主任委员，中华医学会全科医学分会委员、《中华医院管理杂志》副主编，主编和参编多本全科教材，承担过多项课题研究并发表多篇论文。",
        },
        {
          id: 2,
          name: "隋准",
          post: "北京大学人民医院肾内科主任医师，北京大学人民医院内科教研室副主任。",
          pic1: require("@/assets/image2/host/隋准A.png"),
          pic2: require("@/assets/image2/host/隋准B.png"),
          text: "社会任职：<br>北京市医学会内科分会青委会副主委；北京市医学会内科分会委员；中华医学会内科学会血栓学组委员；中华老年肾脏病联盟委员；中华预防医学会肾脏病联盟委员；中关村血液净化联盟常务理术；中关村血液净化联盟科普分会主任委员；卫生部国家考试中心命审题专家；《北京医学》审稿专家；北京市康复医学会肾脏病分会副主任委员<br>专业：<br>从事慢性肾脏病研究多年，承担首都发展课题各一项。参与3项部位级基金课题，2项国家十一五科技支撑计划项目子课题，3项北京市科技委员会课题。近3年发表文章12篇，编写教材2部，参与翻译书籍2部。",
        },
        {
          id: 1,
          name: "吴永浩",
          post: "北京社区健康促进会会长",
          pic1: require("@/assets/image2/host/吴永浩A.png"),
          pic2: require("@/assets/image2/host/吴永浩B.png"),
          text: "曾在北京市卫生局医政处、北京市初级卫生保健办公室、基层卫生处、妇幼与社区卫生处等处室担任职务，也曾担任北京市卫生人才交流服务中心和北京市卫生人员考评中心主任。 现任北京社区健康促进会会长。长期以来一直从事医疗卫生行政和基层卫生管理工作。",
        },
        {
          id: 3,
          name: "周少文",
          post: "中国中医药肾脏病防治联盟秘书长",
          pic1: require("@/assets/image2/host/周少文A.png"),
          pic2: require("@/assets/image2/host/周少文B.png"),
          text: "周少文，血液净化技术专家, 中华人民共和国医药行业腹膜透析设备强制标准的主要起草人；中国中医药肾脏病防治联盟常务副理事长、秘书长，天津院士专家工作发展促进会常务理事、福建生物工程职业技术学院客座教授。牵头项目先后入选国家科技支撑计划项目、工信部疫情防控应急能力建设项目、工信部与卫健委5G+远程医疗试点项目。",
        },
        {
          id: 4,
          name: "刘文虎",
          post: "北京友谊医院肾内科主任",
          pic1: require("@/assets/image2/host/刘文虎A.png"),
          pic2: require("@/assets/image2/host/刘文虎B.png"),
          text: "主任医师，教授，博士生导师 首都医科大学<br>肾病学系主任<br>北京市血液净化质量控制与改进中心主任<br>北京医学会肾脏病学分会副主任委员<br>北京医学会血液净化分会候任主委<br>中国医药教育协会泌尿与血液净化专委员副主任委员<br>中国医促会血液净化治疗与工程技术分会副主任委员<br>中国医药卫生文化协会肾病与血液净化分会副主任委员<br>中国肾脏内科医师协会常委<br>中华肾脏病学会第九届常委<br>享受国务院政府特殊津贴专家<br>北京市“十百千”卫生人才",
        },
        {
          id: 5,
          name: "陈双明",
          post: "中国研究型医院学会血净专委会项目主任",
          pic1: require("@/assets/image2/host/陈双明A.png"),
          pic2: require("@/assets/image2/host/陈双明B.png"),
          text: "",
        },
      ],
    };
  },
  created() {},
  methods: {
    todialog(data) {
      this.dialogData = data;
      this.dialogVisible = true;
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
.hosts {
  color: #fff;
  background: url("../../assets/image2/background.png") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  padding-bottom: 100px;
  position: relative;
  .mydialog {
    ::v-deep .el-dialog {
      color: #000;
      background-color: rgba(255, 255, 255, 0);
    }
    ::v-deep .el-dialog__footer {
      border-top: 1px solid #eee;
      padding: 10px 20px;
      background-color: #fff;
      text-align: center;
    }
    ::v-deep .el-dialog__body {
      background-color: #fff;
      text-align: center;
      img {
        width: 95px;
        height: 110px;
      }
      .title {
        padding: 10px 0;
        h3 {
          padding-top: 5px;
        }
      }
      p {
        overflow-y: scroll;
        height: 230px;
        text-align: left;
        line-height: 20px;
      }
    }
    ::v-deep .el-dialog__header {
      background: url("../../assets/image2/bread.png") no-repeat;
      background-size: 100% 100%;
    }
    .mybtn {
      background-color: #fff;
      border: 1px solid #707070;
      color: #000;
      padding: 8px 20px;
    }
  }

  .bread {
    height: 50px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    position: relative;
    i {
      position: absolute;
      left: 10px;
      top: 17px;
      font-weight: 600;
    }
  }
  .head {
    height: 95px;
    background: url("../../assets/image2/head.png") no-repeat;
    background-size: 100% 100%;
  }
  .content {
    padding: 0 15px;
    .box {
      padding: 15px 0;
      font-size: 14px;
      color: #fff;
      border-bottom: 1px solid #f2daac;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .pic {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        padding: 0 8px;
        flex: 1;
        h3 {
          margin: 0;
          padding-bottom: 10px;
          font-weight: 600;
        }
      }
      .btn {
        cursor: pointer;
        width: 50px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        background: rgba(223, 75, 76, 0.26);
        border: 1px solid #df4b4c;
        border-radius: 5px;
      }
    }
  }
  .bg3 {
    position: absolute;
    background: url("../../assets/image2/BG-3.png") no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 120px;
  }
}
</style>
